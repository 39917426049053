@layer components {
  .topbar {
    box-shadow: 0px 16px 50px rgba(92, 92, 92, 0.05);
    @apply bg-white w-full h-16 px-5 fixed top-0 z-30 grid grid-cols-3;
  }
  .title {
    @apply text-gray-500 font-semibold text-lg self-center ml-3;
  }
  .brand {
    @apply text-blue-hedgehog font-semibold text-lg self-center;
  }
}
