@layer components {
  .text-field-small {
    @apply w-60 pl-4 py-2 border text-sm duration-500 focus:outline-none outline-none placeholder-gray-03 border-gray-04 rounded-lg;
  }
  .text-field-small-disable {
    @apply w-60 pl-4 py-2 border text-sm duration-500 focus:outline-none outline-none placeholder-gray-03 border-gray-04 rounded-lg;
  }
  .text-field-small-required {
    @apply w-60 pl-4 py-2 border text-sm duration-500 focus:outline-none outline-none placeholder-gray-03 border-red-02 bg-white rounded-lg;
  }
  .text-field-small-success {
    @apply w-60 pl-4 py-2 border text-sm duration-500 focus:outline-none outline-none placeholder-gray-03 border-green-02 bg-white rounded-lg;
  }
  .text-field-small-notstring {
    @apply w-60 pl-4 py-2 border text-sm duration-500 focus:outline-none outline-none placeholder-gray-03 border-orange-02 bg-white rounded-lg;
  }
  .text-field-large {
    @apply w-60 p-3 border text-sm text-dark-01 duration-500 focus:outline-none outline-none placeholder-gray-03 border-gray-04 rounded-lg;
  }
  .text-field-large.wide {
    @apply w-full;
  }
  .focusable:focus-within .label-input-small-large {
    @apply text-blue-03
  }
}
